import React from 'react'
import checking1 from "../assets/searching_on_cloud.png"

const Checking = ({checking}) => {
  return (
    <div className={`font-serif lg:w-[410px] w-screen h-screen fixed bg-white p-8 bottom-0 left-1/2 transform -translate-x-1/2 transition-all duration-15000 ease-out ${checking ? 'translate-y-0' : 'translate-y-full'}`}>
        <img src={checking1} />
        <p className='text-sm mt-4'>Your image is sent to our secure servers for analysis</p>
        <p className='text-lg mt-4'>Please wait</p>

        {/* <div className="flex justify-between w-full text-sm h-full items-end">
        <p className="relative right-4">Cypheme official app</p>
        <p className="relative left-4">© Cypheme SAS 2024</p>
        </div> */}
    </div>
  )
}

export default Checking