import React from 'react'
import checkmark from "../assets/checkmark.svg"

const SuccessfulWindow = ({SuccessfulWindowOpen, setSuccessfulWindowOpen}) => {
  return (
    <div className={`lg:w-[410px] font-serif w-screen h-screen fixed bg-[#4BB543] flex flex-col items-center justify-around bottom-0 left-1/2 transform -translate-x-1/2 transition-all duration-5000 ease-out ${SuccessfulWindowOpen ? 'translate-y-0' : 'translate-y-full'}`}>
        <img src={checkmark} className='w-40' style={{ filter: 'drop-shadow(0 4px 10px rgba(0, 0, 0, 0.5))' }} />
        <p className='text-sm bg-slate-50 w-full py-8'>Genuine Certificate</p>
        <p className='bg-slate-100 px-7 py-2 cursor-pointer' onClick={()=> setSuccessfulWindowOpen(false)}>Back</p>
        </div>
  )
}

export default SuccessfulWindow