import React, { useRef, useState, useCallback } from "react";
import video from "../assets/tagcheck.mp4";
import cam from "../assets/cam.png";
import eye from "../assets/eye.png";
import SuccessfulWindow from "./successfulWindow";
import VideoCam from "./videoCam";
import Checking from "./checking";

const Homepage = () => {
  const fileInputRef = useRef(null);
  const [SuccessfulWindowOpen, setSuccessfulWindowOpen] = useState(false);
  const [checking, setChecking] = useState(false);


  //camera functions
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setChecking(true);
    setTimeout(() => {
      setChecking(false);
    }, 6000);
    setSuccessfulWindowOpen(true);
    setTimeout(() => {
      setSuccessfulWindowOpen(false);
      window.location.reload();
    }, 10000);
  };



  return (
    <>
      <div className="lg:w-[410px] w-screen h-screen bg-[#308bff] px-7 py-2 flex flex-col gap-1 font-serif items-center">
        <p className="text-white text-sm">
          Tagcheck allows you to check the authenticity of a product
        </p>
        <p className="text-white text-lg">WITHIN SECONDS</p>
        <div
          className="w-full h-max bg-white relative p-1 mt-9"
          style={{ boxShadow: "8px 8px 8px rgba(0,0,0,.3607843137254902)" }}
        >
          <video
            src={video}
            controls
            autoPlay
            loop
            muted
            className="h-88 object-fill w-full"
          />
          <p className="text-sm px-3 py-1">
            Carefully take a picture of the actual certificate (not of a
            screen). Please make sure the photo is clear enough and properly
            zoomed in
          </p>
        </div>

        <div className="flex gap-3 items-center mt-4">
          <div
            className="border-2 border-white p-3 rounded-full w-20 h-20 flex flex-col items-center justify-center cursor-pointer"
            style={{ boxShadow: "8px 8px 8px rgba(0,0,0,.3607843137254902)" }}
            onClick={handleButtonClick}
          >
            <input
              type="file"
              ref={fileInputRef}
              hidden
              onChange={handleFileChange}
            />
            <img src={cam} className="w-10 h-8" />
          </div>
          <div
            className="border-2 border-white p-3 rounded-full w-16 h-16 flex flex-col items-center justify-center cursor-pointer"
            style={{ boxShadow: "8px 8px 8px rgba(0,0,0,.3607843137254902)" }}
            onClick={handleButtonClick}
          >
            <input
              type="file"
              ref={fileInputRef}
              hidden
              onChange={handleFileChange}
            />
            <img src={eye} className="" />
          </div>
        </div>
        {/* <div className="flex justify-between w-full text-sm h-full items-end">
        <p className="relative right-4">Cypheme official app</p>
        <p className="relative left-4">© Cypheme SAS 2024</p>
        </div> */}
      </div>
      <SuccessfulWindow
        SuccessfulWindowOpen={SuccessfulWindowOpen}
        setSuccessfulWindowOpen={setSuccessfulWindowOpen}
      />
      <Checking checking={checking} />
    </>
  );
};

export default Homepage;
