import './App.css';
import Homepage from './pages/homepage';

function App() {
  return (
    <div className="App h-full flex justify-center bg-[#e6e6e6]">
      <Homepage />
    </div>

  );
}

export default App;
