import React, { useState } from "react";
import { QrReader } from "react-qr-reader";

const VideoCam = ({ }) => {
  const [result, setResult] = useState("");
  const handleScan = (data) => {
    if (data) {
      setResult(data);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div
      className={`lg:w-[410px] w-screen h-screen bg-white fixed transition-all duration-15000 ease-out `}
    >
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        facingMode="environment" 
        style={{ width: '100%', height: "auto" }}
      />
      {/* <QrReader
        onResult={(result, error) => {
          if (!!result) {
            setResult(result?.text);
          }

          if (!!error) {
            console.info(error);
          }
        }}
        style={{ width: "100%" }}
      /> */}
      {/* <button onClick={handleCaptureButtonClick}>Capture Image</button> */}
      {/* {image && <image src={image} />} */}
      <p>{result}</p>
    </div>
  );
};

export default VideoCam;
